html,
body {
  font-family: 'Inter var', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

body {
  overscroll-behavior-y: none;
}

.App {
  min-height: 100vh;
}

.textbox {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.textbox:focus {
  outline: none;
  border-color: #5850ec;
}

.btn {
  background-color: #5850ec;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  margin: 0.5rem;
}
.btn:hover {
  background-color: #6775f5;
}
.btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 0 3px rgba(118, 169, 250, 0.45);
}
.btn.btn-disabled,
.btn.btn-disabled:hover {
  opacity: 0.5;
  pointer-events: none;
}

/* Survey: Hide powered by badge */
a.delighted-web-survey-powered {
  opacity: 0 !important;
  pointer-events: none !important;
}

/*
 * Remove errant Google Maps container which blocks click events on Pins,
 * specifically impacting ClaimView Spotlight.
 * Ref: https://stackoverflow.com/a/69031991
 */
.gm-style-moc {
  height: unset !important;
}

/*
 * For lightbox images, ensure we set a background color so that transparent
 * pngs (e.g. the signature image) don't blend into the background.
 */
img.ril-image-current.ril__image {
  background-color: #fff;
}
